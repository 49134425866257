import React, {Component} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {ApplicationState} from "../../rootReducer";
import {connect} from "react-redux";
import {IconButton} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {Dispatch} from "redux";
import {closeSnackbar} from "./actions";
import withStyles from "@material-ui/core/styles/withStyles";
import {Theme} from "@material-ui/core/es";
import createStyles from "@material-ui/core/es/styles/createStyles";

const style = createStyles((theme: Theme) => ({
    close: {
        color: theme.palette.secondary.main
    }
}));

interface SnackbarProps {
    classes?: any,
    open: boolean,
    label: string,
    closeSnackbar: () => void;
}

class SnackbarComponent extends Component<SnackbarProps, {}> {
    render() {
        const {classes, ...rest} = this.props;

        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.props.open}
                    autoHideDuration={6000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.props.label}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.props.closeSnackbar}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): Partial<SnackbarProps> {
    return {
        open: state.snackbar.open,
        label: state.snackbar.label
    }
}

function mapDispatchToProps(dispatch: Dispatch<any>): Partial<SnackbarProps> {
    return {
        closeSnackbar: () => dispatch(closeSnackbar())
    };
}


const component = withStyles(style)(SnackbarComponent);
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(component);
