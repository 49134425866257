import {combineReducers} from 'redux';
import temperatureReducer from './components/card/temperature/reducer';
import filterReducer from './components/card/filter/reducer';
import snackbarReducer from './components/snackbar/reducer';
import {FilterState, TemperatureState} from './components/card/types';
import {SnackbarState} from "./components/snackbar/types";

export interface ApplicationState {
    temperature: TemperatureState,
    filter: FilterState,
    snackbar: SnackbarState
}

export default combineReducers<ApplicationState>({
    "temperature": temperatureReducer,
    "filter": filterReducer,
    "snackbar": snackbarReducer
});
