import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import fish from '../../assets/svg/fish.svg';
import '../../assets/css/App.css';

const styles = {
    root: {
        flexGrow: 1,
    },
};

export interface Props extends WithStyles<typeof styles> {
}

function SimpleAppBarComponent(props: Props) {
    const {classes} = props;

    return (
        <div className={classes.root}>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <Typography variant="h5" color="inherit">
                        <div className="svg-container">
                            <img src={fish} className="fish-logo" alt="logo" />
                            Danny's vijver
                        </div>
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}

SimpleAppBarComponent.propTypes = {
    classes: PropTypes.object.isRequired,
} as any;

export default withStyles(styles)(SimpleAppBarComponent);
