import {applyMiddleware, createStore, Store} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension'
import thunkMiddleWare from "redux-thunk";
import rootReducer from './rootReducer';

let store: Store;

export default function configureStore(initialState = {}) {
    store = createStore(rootReducer, initialState, composeWithDevTools(
        applyMiddleware(thunkMiddleWare)
    ));
    return store;
};

export {store};
