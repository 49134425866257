import firebase from "firebase/app";
import "firebase/messaging";
import {store} from "../create-redux-store";
import {openSnackbar} from "../components/snackbar/actions";
import {getFilterStatus} from "../components/card/filter/actions";

const initializePush = () => {
    const messaging = firebase.messaging();
    messaging
        .requestPermission()
        .then(() => {
            console.log("Have Permission");
            return messaging.getToken();
        })
        .then((token) => {
            console.log("FCM Token:", token);
            if (token) {
                subscriberToTopic(token, "filter-topic");
            }
        })
        .catch(error => {
            if (error.code === "messaging/permission-blocked") {
                console.log("Please Unblock Notification Request Manually");
            } else {
                console.log("Error Occurred", error);
            }
        });

    messaging.onMessage(payload => {
        console.log(payload);
        // @ts-ignore
        store.dispatch(getFilterStatus());
        store.dispatch(openSnackbar(payload.notification.body));
    });
};

const subscriberToTopic = (token: string, topic: string) => {
    fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic, {
        method: 'POST',
        headers: new Headers({
            'Authorization': 'key=AAAAGOGAox4:APA91bE6Vrg1x3_OSvSuNkOFluQ7F6XsidyGloDSBE84fKNqY6Gt7ClxUUsFgMH5VTUC3H6NtLYzBP_tpY0qS6VCxRGf6d0aJCNGjH5sz4jOUS6IAiGldilgvJRzjVSFhiKRiPzZZ_E0'
        })
    }).then(response => {
        if (response.status < 200 || response.status >= 400) {
            throw String(`Error subscribing to topic: ${response.status} - ${response.body}`);
        }
        console.log('Subscribed to "' + topic + '"');
    }).catch(error => {
        console.error(error);
    })
};

export default initializePush;
