import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Provider from "react-redux/es/components/Provider";
import configureStore from "./create-redux-store";
import config from "./push/firebase.config";
import firebase from "firebase/app";
import "firebase/messaging";
import initializePush from './push/initialize';

firebase.initializeApp(config);

const Component = () => (
    <Provider store={configureStore()}>
        <App/>
    </Provider>
);

const render = () => {
    ReactDOM.render(<Component/>, document.getElementById('root'))
};

render();

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('firebase-messaging-sw.js');
    initializePush();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
