import React, {Component} from "react";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/nl";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import {ListItem, Theme} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import AvTimer from '@material-ui/icons/AvTimer';
import OilTemperature from '../../../../assets/svg/oil-temperature.svg';
import CircularProgress from '@material-ui/core/es/CircularProgress';
import {InlineDatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import createStyles from '@material-ui/core/es/styles/createStyles';
import {CalendarToday, CompareArrows} from '@material-ui/icons';
import IconButton from '@material-ui/core/es/IconButton';
import {Mode, switchMode} from './mode';

moment.locale("nl");

const style = (theme: Theme) => createStyles({
    card: {
        [theme.breakpoints.up('md')]: {
            height: "480px"
        },
    },
    progress: {
        margin: theme.spacing.unit * 4,
    },
    picker: {
        float: "right",
        maxWidth: "70%",
        display: 'none'
    },
    date: {
        color: 'white'
    }
});

export interface TemperatureCardProps {
    classes?: any,
    current: App.TemperaturePointDto | undefined,
    average: number | undefined,
    min: App.TemperaturePointDto | undefined,
    max: App.TemperaturePointDto | undefined,
    loading: boolean | undefined,
    minDate: Date | undefined,
    getTemperature: (date: Date, mode: Mode) => void;
}

export interface TemperatureCardState {
    pickedDate: Date,
    mode: Mode
}

const getMode = (mode: Mode): Array<'year' | 'month' | 'day'> => {
    switch (mode) {
        case Mode.YEAR:
            return ["year"];
        case Mode.MONTH:
            return ["year", "month"];
        case Mode.DAY:
            return ["year", "month", "day"];
        default:
            return ["year", "month", "day"];
    }
};

const getDateformat = (mode: Mode): string => {
    switch (mode) {
        case Mode.YEAR:
            return "YYYY";
        case Mode.MONTH:
            return "MM-YYYY";
        case Mode.DAY:
            return "DD-MM-YYYY";
        default:
            return "DD-MM-YYYY";
    }
};

const getDateTimeformat = (mode: Mode): string => {
    switch (mode) {
        case Mode.YEAR:
        case Mode.MONTH:
            return "DD-MM-YYYY HH:mm:ss";
        case Mode.DAY:
        default:
            return "HH:mm:ss";
    }
};

class TemperatureCard extends Component<TemperatureCardProps, TemperatureCardState> {

    pickerRef: any;

    constructor(props: TemperatureCardProps, context: any) {
        super(props, context);
        this.state = {pickedDate: new Date(), mode: Mode.DAY};
        this.pickerRef = React.createRef();
    }

    componentDidMount(): void {
        this.props.getTemperature(this.state.pickedDate, this.state.mode);
    }

    renderTimestamp: any = (timestamp: string) => {
        return <Moment format={getDateTimeformat(this.state.mode)}>{timestamp}</Moment>
    };

    render() {
        const {classes, ...rest} = this.props;

        const openPicker = (e: any) => {
            if (this.pickerRef.current) {
                this.pickerRef.current.open(e);
            }
        };

        const zoom = (e: any) => {
            this.setState({mode: switchMode(this.state.mode)});
            if (this.pickerRef.current) {
                this.pickerRef.current.open(e);
            }
        };

        return (
            <Card {...rest} className={classes.card}>
                <CardHeader title="Temperatuur"
                            subheader={<Moment format={getDateformat(this.state.mode)}>{this.state.pickedDate.toString()}</Moment>}
                            action={
                                <>
                                    <IconButton>
                                        <CompareArrows onClick={zoom}/>
                                    </IconButton>
                                    <IconButton onClick={openPicker}>
                                        <CalendarToday/>
                                    </IconButton>

                                    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                                        <InlineDatePicker value={this.state.pickedDate}
                                                          views={getMode(this.state.mode)}
                                                          format="DD-MM-YYYY"
                                                          disableFuture={true}
                                                          minDate={this.props.minDate}
                                                          className={classes.picker}
                                                          onChange={date => {
                                                              // dit zou eigenlijk beter redux state gebruiken voor picked date
                                                              // maar dan hebben we ook nog een local state gebruikt :)
                                                              this.setState({pickedDate: date});
                                                              this.props.getTemperature(date, this.state.mode);
                                                          }}
                                                          ref={this.pickerRef}
                                        />
                                    </MuiPickersUtilsProvider>
                                </>
                            }
                />
                {this.props.loading && <CircularProgress className={classes.progress}/>}
                {!this.props.loading &&
                <CardContent>
                    <List className={classes.root}>

                        <ListItem>
                            <Avatar>
                                <img src={OilTemperature} alt="logo"/>
                            </Avatar>
                            <ListItemText primary={this.props.current && `${this.props.current.value} °C`}
                                          secondary={this.props.current && this.props.current.timestamp && this.renderTimestamp(this.props.current.timestamp.toString())}/>
                        </ListItem>

                        <Divider variant="inset"/>

                        <ListItem>
                            <Avatar>
                                <AvTimer/>
                            </Avatar>
                            <ListItemText
                                primary={this.props.average && `${Math.round(this.props.average * 100) / 100} °C`}/>
                        </ListItem>

                        <Divider variant="inset"/>

                        <ListItem>
                            <Avatar>
                                <ArrowDropUp/>
                            </Avatar>
                            <ListItemText primary={this.props.max && `${this.props.max.value} °C`}
                                          secondary={this.props.max && this.renderTimestamp(this.props.max.timestamp)}/>
                        </ListItem>

                        <Divider variant="inset"/>

                        <ListItem>
                            <Avatar>
                                <ArrowDropDown/>
                            </Avatar>
                            <ListItemText primary={this.props.min && `${this.props.min.value} °C`}
                                          secondary={this.props.min && this.renderTimestamp(this.props.min.timestamp)}/>
                        </ListItem>

                    </List>
                </CardContent>
                }
            </Card>
        );
    }
}

// @ts-ignore
export default withStyles(style)(TemperatureCard);
