import {SnackbarState} from './types';
import update from "immutability-helper";
import {CLOSE_SNACKBAR, OPEN_SNACKBAR} from "./actions";

const initialState: SnackbarState = {
    label: "",
    open: false
};

export default function (state: SnackbarState = initialState, action: any) {
    switch (action.type) {
        case OPEN_SNACKBAR:
            return update(state, {
                open: {$set: true},
                label: {$set: action.payload}
            });
        case CLOSE_SNACKBAR:
            return update(state, {
                open: {$set: false},
                label: {$set: ""}
            });
        default:
            return state;
    }
}
