import React from "react";
import GridItem from '../../grid/grid.item';
import TemperatureCard from './childs/temperature.card';
import GraphCard from './childs/graph.card';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {getTemperature} from './actions';
import {ApplicationState} from '../../../rootReducer';
import {Mode} from "./childs/mode";

export interface TemperatureComponentProps {
    temperatureDto: App.TemperatureDto,
    loading: boolean,
    getTemperature: (date: Date, mode: Mode) => void;
}

class TemperatureComponent extends React.Component<TemperatureComponentProps, {}> {

    render() {
        return (
            <>
                <GridItem xs={12} sm={12} md={3}>
                    <TemperatureCard
                        average={this.props.temperatureDto.average}
                        max={this.props.temperatureDto.max}
                        current={this.props.temperatureDto.current}
                        min={this.props.temperatureDto.min}
                        minDate={this.props.temperatureDto.minDate}
                        loading={this.props.loading ? true : undefined}
                        getTemperature={this.props.getTemperature}
                    />
                </GridItem>

                <GridItem xs={12} sm={12} md={9}>
                    <GraphCard points={this.props.temperatureDto.points} average={this.props.temperatureDto.average}/>
                </GridItem>
            </>
        );
    }
}

function mapStateToProps(state: ApplicationState): Partial<TemperatureComponentProps> {
    return {
        temperatureDto: state.temperature.temperatureDto,
        loading: state.temperature.loading
    }
}

function mapDispatchToProps(dispatch: Dispatch<any>): Partial<TemperatureComponentProps> {
    return {
        getTemperature: (date: Date, mode: Mode) => dispatch(getTemperature(date, mode))
    };
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(TemperatureComponent);
