import { FilterState } from '../types';
import update from "immutability-helper";
import { GET_FILTER_BEGIN, GET_FILTER_FAIL, GET_FILTER_SUCCESS } from './actions';

const initialState: FilterState = {
    filterDto: {
        since: undefined,
        status: undefined
    },
    loading: true
};

export default function (state: FilterState = initialState, action: any) {
    switch (action.type) {
        case GET_FILTER_BEGIN:
            return update(state, {
                loading: {$set: true}
            });
        case GET_FILTER_SUCCESS:
            return update(state, {
                filterDto: {$set: action.payload},
                loading: {$set: false}
            });
        case GET_FILTER_FAIL:
            return state;
        default:
            return state;
    }
}
