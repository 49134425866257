// @ts-ignore
export enum Mode {
    DAY = 0, MONTH, YEAR
}

export const switchMode = (mode: Mode): Mode => {
    if (mode >= 2) {
        mode = 0;
    } else {
        mode++;
    }
    // @ts-ignore
    return Mode[Mode[mode]];
};
