import { TemperatureState } from '../types';
import { GET_TEMPERATURE_BEGIN, GET_TEMPERATURE_FAIL, GET_TEMPERATURE_SUCCESS } from './actions';
import update from "immutability-helper";

const initialState: TemperatureState = {
    temperatureDto: {
        current: undefined,
        average: undefined,
        min: undefined,
        max: undefined,
        points: [],
        minDate: undefined
    },
    loading: true
};

export default function (state: TemperatureState = initialState, action: any) {
    switch (action.type) {
        case GET_TEMPERATURE_BEGIN:
            return update(state, {
                loading: {$set: true}
            });
        case GET_TEMPERATURE_SUCCESS:
            return update(state, {
                temperatureDto: {$set: action.payload},
                loading: {$set: false}
            });
        case GET_TEMPERATURE_FAIL:
            return state;
        default:
            return state;
    }
}
