import { Dispatch } from 'redux';
import axios, { AxiosError, AxiosResponse } from 'axios';

export function getFilterStatus() {
    return (dispatch: Dispatch) => {
        dispatch(getFilterBegin());

        axios.get(`https://pond-dashboard-backend.vangeetsomgunther.now.sh/api/filter`)
            .then((response: AxiosResponse) => {
                dispatch(getFilterSuccess(response.data));
            })
            .catch((error: AxiosError) => {
                console.log(error);
                dispatch(getFilterFailure(error));
            });
    }
}

export const GET_FILTER_BEGIN = "GET_FILTER_BEGIN";
export const GET_FILTER_SUCCESS = "GET_FILTER_SUCCESS";
export const GET_FILTER_FAIL = "GET_FILTER_FAIL";

export const getFilterBegin = () => ({
    type: GET_FILTER_BEGIN
});

export const getFilterSuccess = (filterDto: App.FilterDto) => ({
    type: GET_FILTER_SUCCESS,
    payload: filterDto
});

export const getFilterFailure = (error: AxiosError) => ({
    type: GET_FILTER_FAIL,
    payload: error
});

