export const OPEN_SNACKBAR = "OPEN_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

export const closeSnackbar = () => ({
    type: CLOSE_SNACKBAR,
});

export const openSnackbar = (label: string) => ({
    type: OPEN_SNACKBAR,
    payload: label
});
