import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { ListItem, Theme } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { ErrorOutline } from '@material-ui/icons';
import Moment from 'react-moment';
import { ApplicationState } from '../../../rootReducer';
import { Dispatch } from 'redux';
import { getFilterStatus } from './actions';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/es/CircularProgress';
import createStyles from '@material-ui/core/es/styles/createStyles';

const style = (theme: Theme) => createStyles({
    icon: {
        width: "3rem",
        height: "3rem",
        display: "block"
    },
    progress: {
        margin: theme.spacing.unit * 4,
    }
});

export interface FilterComponentProps {
    classes?: any,
    filterDto: App.FilterDto,
    loading: boolean,
    getFilterStatus: () => void;
}

class FilterCard extends Component<FilterComponentProps> {

    componentDidMount(): void {
        this.props.getFilterStatus();
    }

    render() {
        const {classes, ...rest} = this.props;

        return (
            <Card {...rest}>
                <CardHeader title="Filter" />
                {this.props.loading && <CircularProgress className={classes.progress} />}
                {!this.props.loading &&
                <CardContent>
                    <List className={classes.root}>
                        <ListItem>
                            {
                                this.props.filterDto.status ?
                                    <CheckCircleOutline style={{color: 'yellowgreen'}} className={classes.icon} />
                                    : <ErrorOutline style={{color: 'orangered'}} className={classes.icon} />
                            }
                            <ListItemText primary="Status sinds:"
                                          secondary={<Moment
                                              format="DD-MM-YYYY HH:mm:ss">{this.props.filterDto.since && this.props.filterDto.since}</Moment>}
                            />
                        </ListItem>
                    </List>

                </CardContent>
                }
            </Card>
        );
    }
}

function mapStateToProps(state: ApplicationState): Partial<FilterComponentProps> {
    return {
        filterDto: state.filter.filterDto,
        loading: state.filter.loading
    }
}

function mapDispatchToProps(dispatch: Dispatch<any>): Partial<FilterComponentProps> {
    return {
        getFilterStatus: () => dispatch(getFilterStatus())
    };
}

const FilterComponent = withStyles(style)(FilterCard);
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent);
