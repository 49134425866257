import {Dispatch} from 'redux';
import axios, {AxiosError, AxiosResponse} from 'axios';
import moment from 'moment';
import {Mode} from "./childs/mode";

export function getTemperature(date: Date, mode: Mode) {
    return (dispatch: Dispatch) => {
        dispatch(getTemperatureBegin());

        axios.get(`https://pond-dashboard-backend.vangeetsomgunther.now.sh/api/temperature?date=${moment(date).format("YYYY-MM-DD")}&mode=${mode}`)
            .then((response: AxiosResponse) => {
                dispatch(getTemperatureSuccess(response.data));
            })
            .catch((error: AxiosError) => {
                dispatch(getTemperatureFailure(error));
            });
    }
}

export const GET_TEMPERATURE_BEGIN = "GET_TEMPERATURE_BEGIN";
export const GET_TEMPERATURE_SUCCESS = "GET_TEMPERATURE_SUCCESS";
export const GET_TEMPERATURE_FAIL = "GET_TEMPERATURE_FAIL";

export const getTemperatureBegin = () => ({
    type: GET_TEMPERATURE_BEGIN
});

export const getTemperatureSuccess = (temperature: App.TemperatureDto) => ({
    type: GET_TEMPERATURE_SUCCESS,
    payload: temperature
});

export const getTemperatureFailure = (error: AxiosError) => ({
    type: GET_TEMPERATURE_FAIL,
    payload: error
});

