import * as React from 'react';
import SimpleAppBar from './components/appbar/simple-app-bar.component';
import {CssBaseline} from '@material-ui/core';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import GridContainer from './components/grid/grid.container';
import GridItem from './components/grid/grid.item';
import TemperatureComponent from './components/card/temperature/temperature.component';
import FilterCard from './components/card/filter/FilterCard';
import SnackbarComponent from "./components/snackbar/snackbar.component";

const theme = createMuiTheme({
    palette: {
        type: 'dark',
    },
    typography: {useNextVariants: true},
});

function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <div>
                <SnackbarComponent/>
                <SimpleAppBar/>
                <GridContainer>
                    <TemperatureComponent/>
                    <GridItem xs={12} sm={12} md={3}>
                        <FilterCard/>
                    </GridItem>
                </GridContainer>
            </div>
        </MuiThemeProvider>
    )
}

export default App;
