import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import {Theme} from '@material-ui/core';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {grey} from '@material-ui/core/es/colors';
import moment from 'moment';

interface GraphProps {
    classes: any,
    average: number | undefined,
    points: App.TemperaturePointDto[];
}

const style = (theme: Theme) => ({
    card: {
        [theme.breakpoints.up('md')]: {
            height: "480px"
        },
    }
});

const getConfig = (points: App.TemperaturePointDto[], average?: number): Highcharts.Options => (
    {
        series: [{
            type: 'spline',
            data: points.map(value => {
                return [moment(value.timestamp).valueOf(), value.value];
            }),
            name: 'Temperatuur'
        }],
        chart: {
            backgroundColor: grey['800'],
            zoomType: 'x',
            animation: true,
        },
        title: {
            text: ''
        },
        subtitle: {
            text: document.ontouchstart === undefined ?
                'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
        },
        xAxis: {
            gridLineColor: '#707073',
            labels: {
                style: {
                    color: '#E0E0E3'
                }
            },
            lineColor: '#707073',
            minorGridLineColor: '#505053',
            tickColor: '#707073',
            title: {
                style: {
                    color: '#A0A0A3'
                },
            },
            type: 'datetime',
        },
        // @ts-ignore
        yAxis: {
            gridLineColor: '#707073',
            labels: {
                style: {
                    color: '#E0E0E3'
                }
            },
            plotLines: [{
                color: 'yellowgreen',
                dashStyle: 'dash',
                width: 2,
                value: average,
            }],
            lineColor: '#707073',
            minorGridLineColor: '#505053',
            tickColor: '#707073',
            tickWidth: 1,
            title: {
                style: {
                    color: '#A0A0A3'
                },
                text: ''
            }
        },
        tooltip: {
            backgroundColor: grey['700'],
            style: {
                color: '#F0F0F0'
            }
        },
        colors: ["yellowgreen"],
        plotOptions: {
            series: {
                dataLabels: {
                    color: '#B0B0B3',
                },
                marker: {
                    lineColor: '#333'
                },
            },
            boxplot: {
                fillColor: '#505053'
            },
            candlestick: {
                lineColor: 'white'
            },
            errorbar: {
                color: 'white'
            }
        },
        legend: {
            enabled: false
        },
        labels: {
            style: {
                color: '#707073'
            }
        },
        time: {
            timezoneOffset: -120
        }
    }
);

class GraphCard extends Component<GraphProps> {
    render() {
        const {classes, ...rest} = this.props;

        return (
            <Card {...rest} className={classes.card}>
                <CardHeader title="Temperatuur"/>

                <HighchartsReact
                    highcharts={Highcharts}
                    options={getConfig(this.props.points, this.props.average)}
                    {...this.props}
                />
            </Card>
        );
    }
}

export default withStyles(style)(GraphCard);
