const firebaseConfig = {
    apiKey: "AIzaSyDTZ3peMSd4cvModbbMX0h2J6GAq9IeMUs",
    authDomain: "pond-dashboard.firebaseapp.com",
    databaseURL: "https://pond-dashboard.firebaseio.com",
    projectId: "pond-dashboard",
    storageBucket: "pond-dashboard.appspot.com",
    messagingSenderId: "106862519070",
    appId: "1:106862519070:web:787941a63e024509"
};

export default firebaseConfig;
